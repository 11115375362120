.formWrapper {
    padding: 48px;

    & > h2 {
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 24px;
    }

    & > button {
        width: 112px;
        height: 42px;
        padding: 12px 24px 12px 24px;
        border-radius: 6px;
        background-color: var(--ds-main-black);
        border: none;

        & > span {
            font-size: 16px;
            font-weight: 350;
            line-height: 18px;
            letter-spacing: 0;
            color: var(--ds-main-white);
        }
    }

    & > .fileUpload {
        width: 384px;
        height: 200px;
        margin-top: 24px;
        border: 1px dashed var(--ds-bg-light-gray);
        border-radius: 6px;
        cursor: pointer;
        margin-bottom: 48px;
        overflow: hidden;
        position: relative;

        & > div {
            & > div {
                border: none;
            }
        }

        & > div {
            height: 100%;
            & > div {
                height: 100%;
                border: none;

                & > div:first-child {
                    display: none;
                }
                & > div:last-child {
                    height: 100%;
                    & > div {
                        height: 100%;
                    }
                }
            }
        }

        &.fileUploadError {
            border: 1px solid var(--ds-main-red);
        }

        &.withLoadingBlock {
            border: 1px solid var(--ds-main-blue);
        }

        &.withUploadSuccess {
            border: 1px solid var(--ds-bg-green);
        }
    }
}

@keyframes rotateFrame {
    0% {
        transform: rotate(-360deg);
    }
}

.chooseArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: transparent;
    color: transparent;
    border: none;
    & > * {
        background-color: transparent;
        color: transparent;
    }
}

@media (max-width: 767px) {
    .formWrapper {
        & > .fileUpload {
            width: 100%;
            height: auto;

            p {
                text-align: center;
            }
        }
    }
}
